<template>
  <div class="news_page">
    <div class="box_bg">
      <div class="bg_title">
        <div class="bg_title1">最新资讯</div>
        <div class="bg_title2">News</div>
      </div>
    </div>
    <div class="container flex justify-content-between">
      <div class="left">
        <div
          class="row"
          :class="currentIndex === index ? 'rows' : ''"
          v-for="(item, index) in titleList"
          :key="index"
          @click="choose(index, item.id)">
          {{ item.name }}
        </div>
      </div>
      <div class="right" v-if="articlesList!=''">
        <div
          class="item flex align-items-center justify-content-between"
          v-for="(item, index) in articlesList"
          :key="index"
           @click="navigateTo(`/articleDetail/articleDetail/${item.id}`)"
        >
          <div class="i_left flex align-items-center justify-content-between">
            <!-- <div class="box_img">
              <img src="../../static/logo.png" alt="" />
            </div> -->
            <div class="box_timer">
              <div class="box_timer_1">{{(item.created_at).slice(0, 4)}}</div>
              <div class="box_timer_2">{{(item.created_at).slice(5, 10)}}</div>
            </div>
          </div>
          <div class="i_right flex align-items-center justify-content-between">
            <div class="content">
              <div class="title">{{ item.title }}</div>
              <!-- <div class="desc">{{item.order}}</div> -->
            </div>
            <div><i class="el-icon-arrow-right"></i></div>
          </div>
        </div>
      </div>
      <div v-else style="width: 200px;
    left: 600px;
    position: absolute;">
        <el-empty description="暂无商品"></el-empty>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="amount"
        :page-size="10"
        :current-page="page"
        @current-change="currentChange"
        @prev-click="prevPage"
        @next-click="nextPage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentIndex: 0,
      titleList: [
        {
          name: "全部",
          id: 0,
        },
      ],
      amount: 0,
      current: 0,
      articlesList: [],
      page: 1,
    };
  },
  methods: {
    currentChange(e) {
      this.page = e;
      this.getArticles();
    },
    prevPage(e) {
      this.page = e;
      this.getArticles();
    },
    nextPage(e) {
      this.page = e;
      this.getArticles();
    },
    choose(i, id) {
      this.page = 1;
      console.log(i);
      this.currentIndex = i;
      this.current = id;
      this.getArticles();
    },
    getTitle() {
      this.$http.NewsTitle().then((res) => {
        res.data.type.forEach((v) => {
          this.titleList.push(v);
        });
      });
    },
    getArticles() {
      this.$http
        .ArticleList(
          `id=${this.current}` + `&page=${this.page}` + `&size=${10}`
        )
        .then((res) => {
          this.articlesList = res.data.articles;
          this.amount = res.data.count;
        });
    },
  },
  mounted() {
    this.getTitle();
    this.getArticles();
  },
  created () {
    document.title = '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！'
 },
};
</script>
<style lang="scss" scoped>
.news_page {
}
.box_bg {
  width: 100%;
  height: 370px;
  background-image: url("../../static/bg4.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  .bg_title {
    position: absolute;
    width: 950px;
    background-color: rgb(247, 247, 247);
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    padding: 20px;
    .bg_title1 {
      font-size: 16px;
      font-weight: 800;
    }
    .bg_title2 {
      line-height: 30px;
    }
  }
}
.container {
  width: 950px;
  margin: 0px auto;
  margin-top: 50px;
  .left {
    width: 180px;
    height: 300px;
    // background-color: pink;
    .row {
      border-bottom: 1px solid #ccc;
      padding: 10px;
      font-size: 16px;
      cursor: pointer;
      //   font-weight: 600;
    }
    .rows {
      color: #fff;
      background-color: rgb(201, 6, 39);
    }
  }
  .right {
    width: 750px;
    .item {
      width: 100%;
      background-color: rgb(247, 247, 247);
      padding: 5px;
      margin-bottom: 20px;
      box-shadow: 0px 0px 5px #bdbbbb;
      cursor: pointer;
      .i_left {
        width: 230px;
        .box_img {
          width: 120px;
          height: 80px;
          img {
            height: 100%;
            overflow: hidden;
          }
        }
        .box_timer {
          width: 80px;
          text-align: center;
          .box_timer_1 {
            font-size: 22px;
          }
          .box_timer_2 {
            font-size: 16px;
            color: rgb(187, 187, 187);
          }
        }
      }
      .i_right {
        width: calc(100% - 230px);
        padding-right: 20px;
        .content {
          width: 660px;
          padding: 0 10px;
          border-left: 1px solid #ccc;
          .title {
            font-size: 16px;
            font-weight: 800;
          }
          .desc {
            padding-top: 5px;
          }
        }
      }
    }
  }
}
.pagination {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #c70707; //修改后的背景图颜色
    color: #fff;
  }
}
</style>